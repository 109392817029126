<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <VasionButton
        id="btnBack"
        title="Back"
        :isDense="true"
        :icon="'arrow_back'"
        @vasionButtonClicked="backToAuditReport()"
      />
      <h1>{{ headerText }}</h1>
      <div class="btns-container">
        <VasionButton
          id="btnCreateReport"
          :classProp="'primary'"
          :isDisabled="disableSave"
          @vasionButtonClicked="saveReport()"
        >
          {{ saveButtonText }}
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="md-layout">
        <div class="md-layout-item">
          <VasionInput
            id="report-name"
            v-model="reportName"
            width="300"
            class="input-style"
            inputType="top-white"
            title="REPORT NAME"
            required
          />
        </div>
      </div>
      <hr class="tabDivider">
      <div class="w100">
        <div class="innerTabDiv">
          <div>
            <VasionListSelection
              :available-fields.sync="availableUsers"
              :selected-fields.sync="accessUsers"
              :allow-field-ordering="false"
              :available-label-text="'Restricted Users'"
              :selected-label-text="'Access Users'"
              :unique-id="'access-users'"
            />
          </div>
          <div class="separator-div" />
          <div>
            <VasionListSelection
              :available-fields.sync="availableGroups"
              :selected-fields.sync="accessGroups"
              :allow-field-ordering="false"
              :available-label-text="'Restricted Groups'"
              :selected-label-text="'Access Groups'"
              :unique-id="'access-groups'"
            />
          </div>
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'AuditReportConfig',
  components: {
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'TheAuditReport') {
      this.$store.dispatch('reporting/setEditingAuditReport', false)
    }
    next()
  },
  data: function () {
    return {
      accessGroups: [],
      accessUsers: [],
      availableGroups: [],
      availableUsers: [],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      reportName: '',
      showSnackbar: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    auditReport() { return this.$store.state.reporting.auditReport },
    auditReportValues() { return this.$store.state.reporting.auditReportData },
    disableSave() { return this.reportName.length < 1 },
    editingAuditReport() { return this.$store.state.reporting.editingAuditReport },
    groups() { return this.$store.state.common.groups },
    headerText() { return 'Save Audit Report' },
    saveButtonText() { return 'Save' },
    selectedReport() { return this.$store.state.reporting.selectedCustomReportConfig },
    users() { return this.$store.state.common.users },
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('common/getUsers'),
      this.$store.dispatch('common/getGroups'),
    ])

    this.loadUsersAndGroups()

     if (this.editingAuditReport) {
        this.setAuditReportValues()
    }
  },
  methods: {
    backToAuditReport() { this.$router.push({ name: 'TheAuditReport' }) },
    loadUsersAndGroups() {
      this.availableUsers = this.users.map((user) => {
        return {
          name: user.name,
          value: user.value,
        }
      })
      this.availableGroups = this.groups.map((group) => {
        return {
          name: group.name,
          value: group.value,
        }
      })
    },
    async saveReport() {
      this.isLoading = true
      const payload = this.auditReportValues

      let validData = true
      if (this.reportName.trim() === '') {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Report Name must not include only spaces.'
        this.snackbarImage = false
        this.showSnackbar = true
        validData = false
      }

      if (validData) {
        payload.Name = this.reportName
        payload.AccessUserIDs = this.accessUsers.map(a => {
          return a.value
        }).filter(e => e != null)
        payload.AccessGroupIDs = this.accessGroups.map(a => {
          return a.value
        }).filter(e => e != null)

        const response = await this.$store.dispatch('reporting/saveAuditReport', payload)
        if (response.AuditReportID > 0) {
          this.$store.dispatch('reporting/setEditingAuditReport', false)
          await this.$store.dispatch('reporting/getAllSavedAuditReports', true)
          this.snackbarTitle = 'SUCCESS'
          this.snackbarSubTitle = 'Audit report successfully saved'
          this.snackbarImage = true
          this.showSnackbar = true
          setTimeout(() => {
            this.$router.push({ name: 'TheSavedAuditReport', params: { reportId: response.AuditReportID } })
          }, 1500)
        } else {
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'There was an error saving the Audit Report'
          this.snackbarImage = false
          this.showSnackbar = true
        }
      }
      this.isLoading = false
    },
    setAuditReportValues() {
      this.reportName = this.auditReportValues.Name
      this.auditReportValues.AccessUserIDs.forEach(id => {
        const user = this.availableUsers.find(a => a.value === id)
        this.accessUsers.push(user)
      })
      this.auditReportValues.AccessGroupIDs.forEach(id => {
        const group = this.availableGroups.find(a => a.value === id)
        this.accessGroups.push(group)
      })
      this.availableUsers = this.availableUsers.filter(a => {
        return !this.auditReportValues.AccessUserIDs.includes(a.value)
      })
      this.availableGroups = this.availableGroups.filter(a => {
        return !this.auditReportValues.AccessGroupIDs.includes(a.value)
      })
    },
  },

}
</script>

<style scoped>

.tabDivider {
  border-style: solid;
  margin-top: 10px;
}
.w100 {
  width: 100%;
}
</style>
